@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import "bootstrap/scss/bootstrap";
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/effect-fade";
@import "sweetalert2/src/variables";
@import "sweetalert2/src/sweetalert2";

