@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;


.vehicle-card{
    border:1px solid v.$color4;
    text-align: center;

    .image{
        background-image: linear-gradient(65deg, v.$color5, v.$color6);
    }

    h4{
        font-weight: 400;
        padding: 1rem;
    }

    a{
        width: 100%;
    }

    .details{
        display: flex;
        flex-wrap: wrap;

        >div{
            @include m.flexbox(row, center, center, 0.5rem);
            padding: 0.7rem;
            flex:1 0 50%;
            border-top:1px solid v.$color4;
            border-right:1px solid v.$color4;
            white-space:nowrap;

            &:nth-child(2n){
                border-right: none;
            }

            svg{
                color: v.$color1;
            }
        }
    }
}